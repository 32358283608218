import Model, {attr} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import moment from 'moment';
import {computed} from '@ember/object';

export const TOURNAMENT_TYPES = {
  WAGER: 'wager',
  SPINS: 'spins',
  MAX_WIN: 'max_win',
};

export const TOURNAMENT_STATUSES = {
  DRAFT: 'draft',
  LIVE: 'live',
  COMPLETED: 'completed',
  CREDITED: 'credited',
};

const Validations = buildValidations({
  numberOfWinners: {
    description: 'Number of winners',
    validators: [
      validator('presence', true),
      validator('number', {
        gt: 0,
        lte: 100,
        integer: true,
        allowString: true,
      }),
    ],
  },
  minimumStakePerSpin: {
    description: 'Minimum stake per spin',
    disabled: computed('model.type', function () {
      return this.model.type !== TOURNAMENT_TYPES.WAGER;
    }),
    validators: [
      validator('presence', {
        presence: true,
        message: 'This field is required'
      }),
      validator('number', {
        gte: 0.05,
        lte: 100,
        allowString: true,
      }),
    ],
  },
  prizePool: {
    description: 'Total prize pool',
    validators: [
      validator('presence', true),
      validator('number', {
        gt: 0,
        integer: true,
        allowString: true,
      }),
    ],
  },
  nameFirst: {
    description: 'Tournament name',
    validators: [
      validator('presence', true),
      validator('length', {
        max: 7,
      }),
      validator('format', {
        regex: /^[A-Za-z0-9]+$/i,
        message: 'Tournament name must only contain latin chars and must not contain special characters'
      })
    ],
  },
  nameSecond: {
    description: 'Tournament name',
    validators: [
      validator('length', {
        max: 7,
      }),
      validator('format', {
        regex: /^[a-zA-Z0-9]{0,7}$/i,
        allowBlank: true,
        message: 'Tournament name must only contain latin chars and must not contain special characters'
      }),
    ],
  },
  description: {
    description: 'Description',
    validators: [
      validator('length', {
        max: 120,
      }),
    ],
  },
  gamesDescription: {
    description: 'T&Cs - Describe games/providers',
    validators: [
      validator('presence', true),
      validator('length', {
        max: 80,
      }),
    ],
  },
  bannerImageUrlMobile: {
    description: 'Mobile banner ',
    validators: [
      validator('presence', true),
    ],
  },
  bannerImageUrlDesktop: {
    description: 'Desktop banner',
    validators: [
      validator('presence', true),
    ],
  }
});

export default class TournamentModel extends Model.extend(Validations) {
  @attr('string', {defaultValue: TOURNAMENT_TYPES.WAGER}) type;
  @attr('number', {defaultValue: moment.utc().startOf('day').unix()}) startDate;
  @attr('number', {defaultValue: moment.utc().endOf('day').unix()}) endDate;
  @attr('number') gameCategoryId;
  @attr gameSubCategoryId;
  @attr gameIds;
  @attr('number', {defaultValue: 20}) numberOfWinners;
  @attr('string') prizePool;
  @attr('string') bannerImageUrlDesktop;
  @attr('string') bannerImageUrlMobile;
  @attr('string', {defaultValue: ''}) description;
  @attr('string') gamesDescription;
  @attr('string', {defaultValue: 0.5}) minimumStakePerSpin;

  @attr('string') name;
  @attr('string') nameFirst;
  @attr('string') nameSecond;

  @attr('string') status;
  @attr('string') partnerId;

  @attr prizes;

  get isDraft() {
    return this.status === TOURNAMENT_STATUSES.DRAFT
  }

  get extractNameFirst() {
    return this.name?.split(' ')[0];
  }

  get extractNameSecond() {
    return this.name?.split(' ')[1];
  }
}
