import Service, {inject as service} from '@ember/service';
import {task} from "ember-concurrency";
import {tracked} from "@glimmer/tracking";

export default class CountryService extends Service {
  @service store;

  @tracked countries;

  constructor() {
    super(...arguments);
    this.getCountriesTask.perform();
  }

  get countryList() {
    return this.countries;
  }
  @task
  * getCountriesTask () {
    this.countries = yield this.store.findAll('country');
  }
}
