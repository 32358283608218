import Service,{inject as service} from '@ember/service';
import {tracked} from "@glimmer/tracking";

export default class RoleService extends Service {
  @service store;
  @service ajax;

  @tracked roleList;

  constructor() {
    super(...arguments);
    this.roleList = this.peekAll;
  }
  getRoleTitleById(id) {
    const role = this.roleList
      .find(i => +i.id === +id);

    return role?.title || '';
  }

  get peekAll() {
    return this.store.peekAll('role');
  }
}
