import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class CasinoTemplateGameListSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    integration: {embedded: 'always'},
    templateCategory: {embedded: 'always'},
    templateCategories: {embedded: 'always'},
    templateSubCategory: {embedded: 'always'},
    templateSubCategories: {embedded: 'always'},
    countries: {embedded: 'always'},
  }
}
