define("ember-concurrency/-task-property", ["exports", "ember-concurrency/-private/task", "ember-concurrency/-private/task-properties", "ember-concurrency/-private/utils"], function (_exports, _task, _taskProperties, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Task", {
    enumerable: true,
    get: function () {
      return _task.Task;
    }
  });
  Object.defineProperty(_exports, "TaskProperty", {
    enumerable: true,
    get: function () {
      return _taskProperties.TaskProperty;
    }
  });
  (0, _utils.deprecatePrivateModule)('ember-concurrency/-task-property');
});