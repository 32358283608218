import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n  @className=\"table_sticky-header\"\n  @nothingFoundText={{t \"No Tournaments found, please try different search criteria\"}}\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::Tournaments::Tournaments::View::Table::TableItem::SbTableItem\n      @data={{rowData}}\n      @goToEdit={{@goToEdit}}\n      @changeStatusById={{@changeStatusById}}\n      @showWinnersById={{@showWinnersById}}\n      @exportWinnersById={{@exportWinnersById}}\n      @duplicateById={{@duplicateById}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n", {"contents":"<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n  @className=\"table_sticky-header\"\n  @nothingFoundText={{t \"No Tournaments found, please try different search criteria\"}}\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::Tournaments::Tournaments::View::Table::TableItem::SbTableItem\n      @data={{rowData}}\n      @goToEdit={{@goToEdit}}\n      @changeStatusById={{@changeStatusById}}\n      @showWinnersById={{@showWinnersById}}\n      @exportWinnersById={{@exportWinnersById}}\n      @duplicateById={{@duplicateById}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n","moduleName":"backoffice/components/route-components/tournaments/tournaments/view/table/sb-table.hbs","parseOptions":{"srcName":"backoffice/components/route-components/tournaments/tournaments/view/table/sb-table.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

export default class RouteComponentsTournamentsTournamentsViewTableSbTableComponent extends Component {
  @service('tournament') tournamentService;

  tableTitles = [
    'Brand',
    'Status',
    'Name',
    'Banner (mobile)',
    'Description',
    'Type',
    'Start date',
    'End date',
    'Prize (Total)',
    'Actions',
  ];
}
