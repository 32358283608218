import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"player-online-status{{this.isOnlineClass}}\">\n  {{#if this.isOnlineClass}}\n    <i class=\"sb-icon sb-round player-online-status__icon\"></i>\n  {{/if}}\n\n  {{#if this.name}}\n    <span class=\"player-online-status__name\">\n      {{this.name}}\n    </span>\n  {{/if}}\n\n  {{#if this.email}}\n    <span class=\"player-online-status__email\">\n      {{this.email}}\n    </span>\n  {{/if}}\n\n  <span class=\"player-online-status__brand\">\n    {{this.brand}}\n  </span>\n</div>", {"contents":"<div class=\"player-online-status{{this.isOnlineClass}}\">\n  {{#if this.isOnlineClass}}\n    <i class=\"sb-icon sb-round player-online-status__icon\"></i>\n  {{/if}}\n\n  {{#if this.name}}\n    <span class=\"player-online-status__name\">\n      {{this.name}}\n    </span>\n  {{/if}}\n\n  {{#if this.email}}\n    <span class=\"player-online-status__email\">\n      {{this.email}}\n    </span>\n  {{/if}}\n\n  <span class=\"player-online-status__brand\">\n    {{this.brand}}\n  </span>\n</div>","moduleName":"backoffice/components/generic-components/player-status-bar/sb-player-status-bar.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/player-status-bar/sb-player-status-bar.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';

export default class GenericComponentsPlayerStatusBarSbPlayerStatusBarComponent extends Component {
  @service ajax;
  @service partner;
  @service globalVars;

  @tracked player = {
    email: '',
    first_name: '',
    id: '',
    isOnline: null,
    last_name: '',
    brand: ''
  };

  constructor() {
    super(...arguments);

    this.fetchPlayer.perform(this.args.playerId)

    setInterval(() => {
      this.fetchPlayer.perform(this.args.playerId)
    }, 30000);
  }

  get brand() {
    return this.player.brand;
  }

  get name() {
    const {first_name, last_name} = this.player

    if (first_name && last_name) {
      return `${first_name} ${last_name}`
    } else if (first_name) {
      return first_name
    } else if (last_name) {
      return last_name
    }

    return ''
  }

  get email() {
    const {id, email} = this.player

    if (id && email) {
      return `${email} (${id})`
    } else if (email) {
      return email
    } else if (id) {
      return `(${id})`
    }

    return ''
  }

  get isOnlineClass() {
    const {isOnline} = this.player

    if (isOnline === true) {
      return ' player-online-status_online'
    }

    if (isOnline === false) {
      return ' player-online-status_offline'
    }

    return '';
  }

  @task
  * fetchPlayer(id) {
    if (!id) return

    try {
      const {players} = yield this.ajax.request(`${this.globalVars.url.admin_api}/get-player?id=${id}`)
      const {online} = yield this.ajax.post(`${this.globalVars.url.odds_feed_api}/is-online/${id}`)
      const {email, first_name, last_name, partner_id} = players[0]
      const partner = this.partner.getPartnerById(partner_id);

      this.player = {
        email,
        first_name,
        id,
        isOnline: online,
        last_name,
        brand: partner.title
      }
    } catch (e) {
      console.error(e.message)
    }
  }
}
