define("ember-concurrency/-private/external/task/default-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_STATE = void 0;
  const DEFAULT_STATE = _exports.DEFAULT_STATE = {
    last: null,
    lastRunning: null,
    lastPerformed: null,
    lastSuccessful: null,
    lastComplete: null,
    lastErrored: null,
    lastCanceled: null,
    lastIncomplete: null,
    performCount: 0
  };
  Object.freeze(DEFAULT_STATE);
});