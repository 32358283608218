import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';

export default class TournamentsTournamentsEditRoute extends AuthenticatedRoute {
  @service store;
  @service('tournament') tournamentService;

  definer = 'tournaments_tournaments';

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  model(params) {
    return this.store.findRecord('tournament', params.tournament_id)
  }
}
