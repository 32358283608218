import ApplicationAdapter from './application';

export default class IconAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/icon';
  }

  urlForFindAll() {
    return this.host + '/icon';
  }

  urlForCreateRecord() {
    return this.host + '/icon'
  }

  urlForUpdateRecord($id) {
    return this.host + '/icon/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/icon/' + $id
  }
}
