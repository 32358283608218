import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class IconSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'query') {
      const result = payload.items.reduce((documentHash, item) => {
        let {data} = this.normalize(primaryModelClass, item);
        documentHash.data.push(data);
        return documentHash;
      }, {data: [], meta: {}})
      result.meta.count = payload.count;
      return result;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
