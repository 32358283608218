import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';
import {task} from "ember-concurrency";

export default class TournamentsTournamentsCreateRoute extends AuthenticatedRoute {
  @service('tournament') tournamentService;
  @service store;
  @service notifications;
  @service template;

  definer = 'tournaments_tournaments';
  duplicate_from_id = null;

  queryParams = {
    duplicate_from_id: {
      refreshModel: false
    }
  };

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  model(params) {
    if(params.duplicate_from_id) {
      return this.getTournamentTask.perform(params.duplicate_from_id);
    }

    return this.store.createRecord('tournament', {
      prizes: this.tournamentService.createPrizes(20),
      gameIds: [],
    });
  }

  @task
  * getTournamentTask(id) {
    return yield this.store.findRecord('tournament', id)
      .then(data => {
        return this.store.createRecord('tournament', {
          prizes: data.prizes.map(prize => this.tournamentService.createPrize(prize.place, prize.reward)),
          bannerImageUrlDesktop: data.bannerImageUrlDesktop,
          bannerImageUrlMobile: data.bannerImageUrlMobile,
          type: data.type,
          gameCategoryId: data.gameCategoryId,
          gameSubCategoryId: data.gameSubCategoryId,
          gameIds: data.gameIds,
          numberOfWinners: data.numberOfWinners,
          name: data.name,
          nameFirst: data.extractNameFirst || data.nameFirst,
          nameSecond: data.extractNameSecond || data.nameSecond,
          description: data.description,
          gamesDescription: data.gamesDescription,
          minimumStakePerSpin: data.minimumStakePerSpin && data.minimumStakePerSpin !== '0.00'
            ? data.minimumStakePerSpin
            : 0.5,
          prizePool: this.template.withNumber(data.prizePool),
        });
      }).catch(payload => {
        this.notifications.error('Could not find tournament data', {
          autoClear: true
        });
      })
  }
}
