define("ember-fetch/types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPlainObject = isPlainObject;
  function isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }
});