import AuthenticatedRoute from '../../authenticated';

export default class SettingsUsersRolesUsersRoute extends AuthenticatedRoute {
  definer = 'settings_users_roles_key_users'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
  }
}
