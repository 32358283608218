define("ember-concurrency/-private/external/generator-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GeneratorStepResult = _exports.GeneratorState = void 0;
  class GeneratorStepResult {
    constructor(value, done, errored) {
      this.value = value;
      this.done = done;
      this.errored = errored;
    }
  }
  _exports.GeneratorStepResult = GeneratorStepResult;
  class GeneratorState {
    constructor(generatorFactory) {
      this.done = false;
      this.generatorFactory = generatorFactory;
      this.iterator = null;
    }
    step(resolvedValue, iteratorMethod) {
      try {
        let iterator = this.getIterator();
        let {
          value,
          done
        } = iterator[iteratorMethod](resolvedValue);
        if (done) {
          return this.finalize(value, false);
        } else {
          return new GeneratorStepResult(value, false, false);
        }
      } catch (e) {
        return this.finalize(e, true);
      }
    }
    getIterator() {
      if (!this.iterator && !this.done) {
        this.iterator = this.generatorFactory();
      }
      return this.iterator;
    }
    finalize(value, errored) {
      this.done = true;
      this.iterator = null;
      return new GeneratorStepResult(value, true, errored);
    }
  }
  _exports.GeneratorState = GeneratorState;
});