import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"password-field\">\n  <Input\n    @type={{if this.isPasswordHidden \"password\" \"text\"}}\n    {{on 'keyup' @passwordMessage}}\n    @value={{@passwordValue}}\n    class=\"password-input\"\n  />\n\n  <!-- Toggle button to show/hide password -->\n  <button\n    type=\"button\"\n    {{on \"click\" this.togglePasswordVisibility}}\n    class=\"btn-toggle-password\"\n  >\n    <i class=\"sb-icon {{if this.isPasswordHidden 'sb-eye-closed-password' 'sb-eye-password'}}\"></i>\n  </button>\n</div>", {"contents":"<div class=\"password-field\">\n  <Input\n    @type={{if this.isPasswordHidden \"password\" \"text\"}}\n    {{on 'keyup' @passwordMessage}}\n    @value={{@passwordValue}}\n    class=\"password-input\"\n  />\n\n  <!-- Toggle button to show/hide password -->\n  <button\n    type=\"button\"\n    {{on \"click\" this.togglePasswordVisibility}}\n    class=\"btn-toggle-password\"\n  >\n    <i class=\"sb-icon {{if this.isPasswordHidden 'sb-eye-closed-password' 'sb-eye-password'}}\"></i>\n  </button>\n</div>","moduleName":"backoffice/components/generic-components/form-inputs/input-password.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/form-inputs/input-password.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

export default class InputPasswordComponent extends Component {
  @tracked isPasswordHidden = true;

  @action
  togglePasswordVisibility() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }
}
