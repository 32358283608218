define("ember-modifier/-private/function-based/modifier-manager", ["exports", "@ember/modifier", "ember-modifier/-private/compat"], function (_exports, _modifier, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // Wraps the unsafe (b/c it mutates, rather than creating new state) code that
  // TS does not yet understand.
  function installElement(state, element) {
    // SAFETY: this cast represents how we are actually handling the state machine
    // transition: from this point forward in the lifecycle of the modifier, it
    // always behaves as `InstalledState<S>`. It is safe because, and *only*
    // because, we immediately initialize `element`. (We cannot create a new state
    // from the old one because the modifier manager API expects mutation of a
    // single state bucket rather than updating it at hook calls.)
    const installedState = state;
    installedState.element = element;
    return installedState;
  }
  class FunctionBasedModifierManager {
    constructor(options) {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)(true ? '3.22' : '3.13'));
      _defineProperty(this, "options", void 0);
      this.options = {
        eager: options?.eager ?? true
      };
    }
    createModifier(factoryOrClass) {
      const instance = (0, _compat.isFactory)(factoryOrClass) ? factoryOrClass.class : factoryOrClass;
      return {
        element: null,
        instance: instance
      };
    }
    installModifier(createdState, element, args) {
      const state = installElement(createdState, element);
      const {
        positional,
        named
      } = args;
      const teardown = createdState.instance(element, positional, named);
      if (typeof teardown === 'function') {
        state.teardown = teardown;
      }
      if (true && this.options.eager) {
        (0, _compat.consumeArgs)(args);
      }
    }
    updateModifier(state, args) {
      if (state.teardown) {
        state.teardown();
      }
      const teardown = state.instance(state.element, args.positional, args.named);
      if (typeof teardown === 'function') {
        state.teardown = teardown;
      }
      if (true && this.options.eager) {
        (0, _compat.consumeArgs)(args);
      }
    }
    destroyModifier(state) {
      if (typeof state.teardown === 'function') {
        state.teardown();
      }
    }
  }
  _exports.default = FunctionBasedModifierManager;
});