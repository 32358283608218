define("ember-assign-helper/helpers/assign", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assign = assign;
  _exports.default = void 0;
  function assign(params) {
    return Object.assign({}, ...params);
  }
  var _default = _exports.default = (0, _helper.helper)(assign);
});