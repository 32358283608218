import AuthenticatedRoute from '../authenticated';
import {inject as service} from '@ember/service';
export default class CmsIcons extends AuthenticatedRoute {
  @service navigation;
  definer = 'cms_icons';

  model(params) {
    return params;
  }

  activate() {
    this.navigation.customHeaderTitle = null;
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }
}
