import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UserNotifyService extends Service {
  @service globalVars;
  @service currentUser;
  @service store;

  @tracked mainWithdrawRequestsCount = 0;
  @tracked adminWithdrawRequestsCount = 0;

  @tracked userNotificationConfig = null;

  get documentsCount() {
    return 0;
  }
  get adminDepositsCount() {
    return 0
  }

  get notificationCount() {
    return 0
  }

  reload() {
  }
}
