define("ember-concurrency/-private/external/scheduler/policies/bounded-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BoundedPolicy {
    constructor(maxConcurrency) {
      this.maxConcurrency = maxConcurrency || 1;
    }
  }
  var _default = _exports.default = BoundedPolicy;
});