import Model, { attr } from '@ember-data/model';

export default class PartnerStaticPageModel extends Model {
  @attr title;
  @attr partner_id;
  @attr params;
  @attr url;
  @attr is_active;
  @attr hide_in_menu;
  @attr('string', { defaultValue: 'Hello' }) body_en;
  @attr('string', { defaultValue: 'Hello' }) body_ru;
  @attr('string', { defaultValue: 'Hello' }) body_ar;
  @attr('string', { defaultValue: 'Hello' }) body_az;
  @attr('string', { defaultValue: 'Hello' }) body_es;
  @attr('string', { defaultValue: 'Hello' }) body_fa;
  @attr('string', { defaultValue: 'Hello' }) body_fr;
  @attr('string', { defaultValue: 'Hello' }) body_hi;
  @attr('string', { defaultValue: 'Hello' }) body_md;
  @attr('string', { defaultValue: 'Hello' }) body_ph;
  @attr('string', { defaultValue: 'Hello' }) body_pt;
  @attr('string', { defaultValue: 'Hello' }) body_ro;
  @attr('string', { defaultValue: 'Hello' }) body_tk;
  @attr('string', { defaultValue: 'Hello' }) body_tr;
  @attr('string', { defaultValue: 'Hello' }) body_it;
  @attr('string', { defaultValue: 'Hello' }) body_ka;
  @attr('string', { defaultValue: 'Hello' }) body_de;
  @attr('string', { defaultValue: 'Hello' }) body_ua;
  @attr('string', { defaultValue: 'Hello' }) body_kz;
  @attr('string', { defaultValue: 'Hello' }) body_sv;

  toJSON() {
    return {
      title: this.title,
      partner_id: this.partner_id,
      params: this.params,
      url: this.url,
      is_active: this.is_active,
      hide_in_menu: this.hide_in_menu,
      body_en: this.body_en,
      body_ru: this.body_ru,
      body_ar: this.body_ar,
      body_az: this.body_az,
      body_es: this.body_es,
      body_fa: this.body_fa,
      body_fr: this.body_fr,
      body_hi: this.body_hi,
      body_md: this.body_md,
      body_ph: this.body_ph,
      body_pt: this.body_pt,
      body_ro: this.body_ro,
      body_tk: this.body_tk,
      body_tr: this.body_tr,
      body_it: this.body_it,
      body_ka: this.body_ka,
      body_de: this.body_de,
      body_ua: this.body_ua,
      body_kz: this.body_kz,
      body_sv: this.body_sv
    }
  }
}
