import Service from '@ember/service';
import {tracked} from "@glimmer/tracking";

export const ENVS = {
  DEV: "dev",
  STG: "stg",
  PROD: "prod"
};

export default class EnvironmentService extends Service {
  @tracked currentEnv = this.getEnv();

  // todo get env based on configuration, not domain
  getEnv() {
    const hostname = window.location.hostname;
    if (hostname.includes('77stg')) {
      return ENVS.STG;
    } else if (hostname.includes('77dev')) {
      return ENVS.DEV;
    } else {
      return ENVS.PROD;
    }
  }
}
