define("ember-concurrency/-private/external/scheduler/policies/restartable-policy", ["exports", "ember-concurrency/-private/external/scheduler/policies/bounded-policy", "ember-concurrency/-private/external/scheduler/policies/execution-states"], function (_exports, _boundedPolicy, _executionStates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CANCELLED = (0, _executionStates.makeCancelState)(`it belongs to a 'restartable' Task that was .perform()ed again`);
  class RestartableReducer {
    constructor(numToCancel) {
      this.numToCancel = numToCancel;
    }
    step() {
      if (this.numToCancel > 0) {
        this.numToCancel--;
        return CANCELLED;
      } else {
        return _executionStates.STARTED;
      }
    }
  }
  class RestartablePolicy extends _boundedPolicy.default {
    makeReducer(numRunning, numQueued) {
      return new RestartableReducer(numRunning + numQueued - this.maxConcurrency);
    }
  }
  var _default = _exports.default = RestartablePolicy;
});