import AuthenticatedRoute from '../../../authenticated';

export default class RiskManagementPlayersProfileTransactionHistoryLogRoute extends AuthenticatedRoute {
  definer = 'r_m_players_key_transaction_history_log'

  queryParams = {
    category: {refreshModel: true}
  }

  model() {
    return this.paramsFor('risk-management.players.profile');
  }
}
