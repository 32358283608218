import Service from '@ember/service';

export default class CurrencyService extends Service {
  currencyMap = {
    usd: 1,
    eur: 3,
    cad: 2,
    aud: 10,
    gbp: 39
  }

  strongCurrencies = [
    this.currencyMap.eur,
    this.currencyMap.usd,
    this.currencyMap.cad,
    this.currencyMap.aud,
    this.currencyMap.gbp
  ];
}
