import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n\n{{yield}}", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n\n{{yield}}","moduleName":"backoffice/components/route-components/casino/settings/template/sb-template.hbs","parseOptions":{"srcName":"backoffice/components/route-components/casino/settings/template/sb-template.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

export default class RouteComponentsCasinoSettingsTemplateSbTemplateComponent extends Component {
  @service router;

  get menuItems() {
    let template_id = this.router.currentRoute.parent.params.template_id

    return [
      {
        name: 'Order',
        definer: 'casino_settings_key_order',
        linkTo: {
          path: 'casino.settings.template.order',
          model: template_id
        },
      },
      {
        name: 'Categories',
        definer: 'casino_settings_key_categories',
        linkTo: {
          path: 'casino.settings.template.categories',
          model: template_id
        }
      },
      {
        name: 'Sub Categories',
        definer: 'casino_settings_key_categories',
        linkTo: {
          path: 'casino.settings.template.sub-categories',
          model: template_id
        }
      },
      {
        name: 'Types',
        definer: 'casino_settings_key_categories',
        linkTo: {
          path: 'casino.settings.template.types',
          model: template_id
        }
      },
      {
        name: 'Games',
        definer: 'casino_settings_key_games',
        linkTo: {
          path: 'casino.settings.template.games',
          model: template_id
        }
      },
      {
        name: 'Manufacturers',
        definer: 'casino_settings_key_providers',
        linkTo: {
          path: 'casino.settings.template.providers',
          model: template_id
        }
      },
      {
        name: 'Wagering Contribution',
        definer: 'casino_settings_key_wagering_contribution',
        linkTo: {
          path: 'casino.settings.template.wagering-contribution',
          model: template_id
        }
      }
    ];
  }
}
