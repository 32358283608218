import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LayoutComponents::Table::SbTable\n  @titles={{this.tableTitles}}\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n>\n  {{#each @data as |item|}}\n    <RouteComponents::Cms::Icons::IconsTable::IconsTableItem::SbIconsTableItem\n      @data={{item}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n", {"contents":"<LayoutComponents::Table::SbTable\n  @titles={{this.tableTitles}}\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n>\n  {{#each @data as |item|}}\n    <RouteComponents::Cms::Icons::IconsTable::IconsTableItem::SbIconsTableItem\n      @data={{item}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n","moduleName":"backoffice/components/route-components/cms/icons/icons-table/sb-icons-table.hbs","parseOptions":{"srcName":"backoffice/components/route-components/cms/icons/icons-table/sb-icons-table.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsCmsIconsIconsTableSbIconsTable extends Component {
  tableTitles = [
    'Date',
    'Icon name',
    'Image'
  ];
}
