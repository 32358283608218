import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {reads} from '@ember/object/computed';
import {tracked} from '@glimmer/tracking';

const Validations = buildValidations({
  username: {
    description: 'Username',
    validators: [
      validator('presence', {presence: true, message: 'This field is required'}),
      validator('length', {
        min: 6,
        max: 15,
      }),
      validator('unique-fields', {
        showSuggestions: true,
        isNew: reads('model.isNew'),
      }),
    ],
  },
  email: {
    description: 'Email',
    validators: [
      validator('presence', {
        presence: true,
        message: 'This field is required'
      }),
      validator('format', {
        type: 'email',
        message: 'Email is invalid'
      })
    ]
  },
  phone: {
    validators: [
      validator('phone', {
        showSuggestions: true,
        message: 'Phone number is invalid'
      }),
    ]
  },
  password: {
    description: 'Password',
    validators: [
      validator('presence', {presence: true, message: 'This field is required'}),
      validator('format', {
        regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{7,}$/,
        message: '{description} must include minimum eight characters, at least one letter',
      }),
      validator('length', {
        isWarning: true,
        max: 20,
        message: 'Password is too long (maximum is 20 characters).',
      }),
      validator('length', {
        isWarning: true,
        min: 8,
        message: 'Password is too short (minimum is 8 characters).',
      }),
    ],
  },
  partner_id: {
    description: 'Brand',
    validators: [
      validator('presence', {presence: true, message: 'This field is required'})
    ]
  },
  role_id: {
    description: 'Role',
    validators: [
      validator('presence', {presence: true, message: 'This field is required'})
    ]
  }
});
export default class UserModel extends Model.extend(Validations) {
  @attr username;
  @attr password;
  @attr('number') role_id;
  @attr status;
  @attr parent_id;
  @attr('number') partner_id;
  @attr deleted;
  @attr full_name;
  @attr cashbox_id;
  @attr('boolean', {defaultValue: true}) is_active;
  @attr created_at;
  @attr phone;
  @attr email;

  @attr retail_administrators;
  @attr retail_cashiers_on_administrator;
  @attr retail_managers_on_administrator;
  @attr retail_managers;
  @attr retail_cashiers_on_manager;
  @attr retail_cashiers;
  @attr percent;
  @attr affiliate;
  @attr sub_affiliate;

  @belongsTo('role', {async: false, inverse: null}) role;
  @belongsTo('user', {async: false, inverse: null}) parent;
  @belongsTo('cashbox', {async: false, inverse: null}) cashbox;
  @belongsTo('partner', {async: false, inverse: null}) partner;
  @belongsTo('user-notification-config', {async: false, inverse: null}) userNotificationConfig;
  @belongsTo('affiliate-setting', {async: false, inverse: null}) affiliateSetting;
  @hasMany('affiliate-link', {async: false, inverse: null}) affiliateLinks;
  @hasMany('admin-account', {async: false, inverse: null}) adminAccounts;
  @hasMany('admin-account', {async: false, inverse: null}) mainAdminAccounts;
  @hasMany('admin-account', {async: false, inverse: null}) freezeAndMainAccounts;
  @hasMany('rolePermissions', {async: false, inverse: null}) rolePermissions;
  @hasMany('filtering-player', {async: false, inverse: null}) filteringPlayers;
  @hasMany('site-content-favorite', {async: false, inverse: null}) siteContentFavorites;
  @hasMany('group-admin-partner', {async: false, inverse: null}) groupAdminPartners;

  @tracked title = this.full_name;

  get isSystem() {
    return this.partner_id === 0 && this.role.get('roleType.title') === 'System';
  }

  get isWithoutPartner() {
    return +this.partner_id === 0;
  }

  get isGroupAdmin() {
    return this.partner_id === 0 && this.role.get('roleType.title') === 'Group Admin';
  }

  get isSystemOrDefault() {
    return this.partner_id === 0 || this.role.get('roleType.title') === 'Default';
  }

  get isAffiliate() {
    return this.role.get('roleType').get('title') === 'Affiliate';
  }

  get isSubAffiliate() {
    return this.role.get('roleType').get('title') === 'Sub Affiliate';
  }

  mainAccountByCurrency(currencyId) {
    let type = this.store
      .peekAll('admin-account-type')
      .filter((i) => i.wallet === 'main')
      .find((i) => i.owner === 'user');
    return this.mainAdminAccounts.find((item) => parseInt(item.type_id) === parseInt(type.id) && parseInt(item.currency_id) === parseInt(currencyId));
  }

  get currencies() {
    return this.accounts.map((i) => i.currency);
  }

  get adminCurrencies() {
    return this.adminAccounts.map((i) => i.currency);
  }

  get isAdministrator() {
    return this.role.get('roleType.title') === 'Administrator';
  }

  get isManager() {
    return this.role.get('roleType.title') === 'Manager';
  }

  get isCashier() {
    return this.role.get('roleType.title') === 'Cashier';
  }

  get isDefault() {
    return this.role.get('roleType.title') === 'Default';
  }
}
