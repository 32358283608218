define("ember-concurrency/-private/external/task/task", ["exports", "ember-concurrency/-private/external/task/taskable", "ember-concurrency/-private/external/task-instance/executor"], function (_exports, _taskable, _executor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Task = void 0;
  class TaskLinkProxy {
    constructor(task, performType, linkedObject) {
      this.task = task;
      this.performType = performType;
      this.linkedObject = linkedObject;
    }
    perform(...args) {
      return this.task._performShared(args, this.performType, this.linkedObject);
    }
  }
  class Task extends _taskable.Taskable {
    constructor(options) {
      super(options);
      this.generatorFactory = options.generatorFactory;
      this.perform = this._perform.bind(this);
    }
    linked() {
      let linkedObject = (0, _executor.getRunningInstance)();
      if (!linkedObject) {
        throw new Error(`You can only call .linked() from within a task.`);
      }
      return new TaskLinkProxy(this, _executor.PERFORM_TYPE_LINKED, linkedObject);
    }
    unlinked() {
      return new TaskLinkProxy(this, _executor.PERFORM_TYPE_UNLINKED, null);
    }
    toString() {
      return `<Task:${this.name}>`;
    }
    _clone() {
      return new Task({
        context: this.context,
        debug: this.debug,
        env: this.env,
        generatorFactory: this.generatorFactory,
        group: this.group,
        hasEnabledEvents: this.hasEnabledEvents,
        name: this.name,
        onStateCallback: this.onStateCallback,
        scheduler: this.scheduler
      });
    }
    _curry(...args) {
      let task = this._clone();
      task._curryArgs = [...(this._curryArgs || []), ...args];
      return task;
    }
    _perform(...args) {
      return this._performShared(args, _executor.PERFORM_TYPE_DEFAULT, null);
    }
    _performShared(args, performType, linkedObject) {
      let fullArgs = this._curryArgs ? [...this._curryArgs, ...args] : args;
      let taskInstance = this._taskInstanceFactory(fullArgs, performType, linkedObject);
      if (performType === _executor.PERFORM_TYPE_LINKED) {
        linkedObject._expectsLinkedYield = true;
      }
      if (!this._isAlive) {
        // a task linked to a dead lifetime should immediately cancel.
        taskInstance.cancel();
      }
      this.scheduler.perform(taskInstance);
      return taskInstance;
    }

    // eslint-disable-next-line no-unused-vars
    _taskInstanceOptions(args, performType, _linkedObject) {
      let generatorFactory = () => this.generatorFactory(args);
      let taskInstanceOptions = {
        task: this,
        args,
        executor: new _executor.TaskInstanceExecutor({
          generatorFactory,
          env: this.env,
          debug: this.debug
        }),
        performType,
        hasEnabledEvents: this.hasEnabledEvents
      };
      return taskInstanceOptions;
    }
  }
  _exports.Task = Task;
});